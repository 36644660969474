import { allClientSideFlags } from '@gonfalon/constants';
import { DogfoodClient, getDogfoodClient } from '@gonfalon/dogfood-client';

export function createFlagFunction<T>(flagKey: string, defaultValue: T): () => T {
  return () => {
    let client: DogfoodClient | undefined;

    try {
      client = getDogfoodClient();
    } catch (err) {
      const flagValue = allClientSideFlags()[flagKey];

      if (typeof flagValue === 'undefined') {
        if (!__TEST__) {
          // eslint-disable-next-line no-console
          console.error(`Flag "${flagKey}" not defined in client-side flags`);
        }

        return defaultValue;
      }

      return flagValue as T;
    }

    return client.variation(flagKey, defaultValue) as T;
  };
}
