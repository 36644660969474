export function createConstantFunction<T = unknown>(name: string, options?: { optional: false }): () => T;
export function createConstantFunction<T = unknown>(name: string, options: { optional: true }): () => T | undefined;
export function createConstantFunction<T = unknown>(
  name: string,
  options?: { optional: boolean },
): () => T | undefined {
  return () => {
    const value = window.ldConstants[name];

    if (typeof value !== 'undefined') {
      return value as T;
    }

    if (!options?.optional) {
      throw new Error(`Property "${name}" not defined in window.ldConstants`);
    }
  };
}
