import { getProjectAndEnvironmentFromUrl } from './getProjectAndEnvironmentFromUrl';

type FetchConfigOptions = {
  authenticated: boolean;
};

export function getConfigUrl(config: FetchConfigOptions) {
  const { href } = window.location;

  if (!config.authenticated) {
    return new URL('/internal/config/anonymous', href).toString();
  }

  const projectAndEnvironment = getProjectAndEnvironmentFromUrl(new URL(href));
  const url = new URL('/internal/config/authenticated', href);

  if (projectAndEnvironment.project) {
    url.searchParams.append('project', projectAndEnvironment.project);
  }

  if (projectAndEnvironment.environment) {
    url.searchParams.append('environment', projectAndEnvironment.environment);
  }

  return url.toString();
}

export const fetchConfig = async (config: FetchConfigOptions) => {
  const url = getConfigUrl(config);

  const res = await fetch(url);

  if (!res.ok) {
    throw new Error(`HTTP ${res.status}: Failed to load config.\n${res.statusText}`);
  }

  return res.json();
};
