import { datadogRum } from '@datadog/browser-rum-slim';

function isPerformanceNavigationTimingEntry(
  performanceEntry: PerformanceEntry,
): performanceEntry is PerformanceNavigationTiming {
  return performanceEntry.entryType === 'navigation';
}

export function trackPageLoadCustomMetrics() {
  const observer = new PerformanceObserver((list: PerformanceObserverEntryList) => {
    const navigationEntry = list.getEntries()[0];

    if (navigationEntry && isPerformanceNavigationTimingEntry(navigationEntry)) {
      // Add the html_request_time custom metric: This accounts for the time
      // from when the browser begins the request until the final byte of the
      // response is received. It does not include any potential unloading time
      // for a previous page, time the request might have been queued, time
      // spent parsing the HTML response, etc.
      //
      // See https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming
      const htmlRequestTime = navigationEntry.responseEnd - navigationEntry.requestStart;
      datadogRum.addTiming('html_request_time', htmlRequestTime);
      observer.disconnect();
    }
  });

  observer.observe({ buffered: true, type: 'navigation' });
}
