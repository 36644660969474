export function captureExceptions() {
  const exceptions: Error[] = [];

  const handleError = (event: ErrorEvent) => {
    exceptions.push(event.error);
  };

  const handleRejection = (event: PromiseRejectionEvent) => {
    exceptions.push(event.reason);
  };

  window.addEventListener('error', handleError);
  window.addEventListener('unhandledrejection', handleRejection);

  return () => {
    window.removeEventListener('error', handleError);
    window.removeEventListener('unhandledrejection', handleRejection);

    return exceptions;
  };
}
