import { captureExceptions, initializeTelemetry } from '@gonfalon/telemetry';

import { fetchConfig } from './fetchConfig';
import { shimGlobals } from './shimGlobals';

import 'stylesheets/common.css';

const stopCapturingExceptions = captureExceptions();

// eslint-disable-next-line @typescript-eslint/no-floating-promises
(async () => {
  const ldContainer = document.querySelector('.ld-container') as HTMLElement;
  // We have to set window.analytics here, otherwise it will be set to the element[id="analytics"] on some v1 pages
  // (see http://2ality.com/2012/08/ids-are-global.html).  It's used for the Segment script in initSegmentAndCandu.ts
  window.analytics = window.analytics || [];

  const staticAssetPath = document.documentElement.dataset.staticAssetPath || '';
  const authenticated = !('isUnauthenticated' in document.documentElement.dataset);
  window.__bundle__ = document.documentElement.dataset.bundle || '';

  window.__initial_state__ = {};
  const initialStateFlag = ldContainer.dataset.initialStateFlag;
  const initialStateJoinExistingOrgConfig = ldContainer.dataset.initialStateJoinExistingOrgConfig;
  if (initialStateFlag) {
    window.__initial_state__.flag = JSON.parse(initialStateFlag);
  }
  if (initialStateJoinExistingOrgConfig) {
    window.__initial_state__.joinExistingOrgConfig = JSON.parse(initialStateJoinExistingOrgConfig);
  }

  // dynamically sets the webpack public path so that static assets can be served from a configurable domain/path
  if (staticAssetPath) {
    // cannot be `window.__webpack_public_path__` and must be set within the webpack bundle (eg, cannot be defined in header.html)
    // if you change this, make sure that dynamic loading of javascript chunks still works
    // @ts-expect-error There is a type definition for this in GonfalonWindow,
    //                  but TS complains because of the implicit global write
    __webpack_public_path__ = staticAssetPath;
  }

  const loader = async () =>
    authenticated
      ? import(/* webpackChunkName: "loadApp" */ './loadApp')
      : import(/* webpackChunkName: "loadUnauthenticated" */ './loadUnauthenticated');

  const config = await fetchConfig({ authenticated });

  // TODO: work towards removing this entirely!
  shimGlobals(config);

  await initializeTelemetry(stopCapturingExceptions());
  (await loader()).load();
})();
