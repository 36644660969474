// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const shimGlobals = (config: any) => {
  window.ldConstants = config;

  window.__initial_state__.profile = config.profile;

  if (config.currentProject) {
    window.__initial_state__.currentProject = config.currentProject;
  }

  if (config.currentEnvironment) {
    window.__initial_state__.currentEnvironment = config.currentEnvironment;
  }
};
