import { datadogRum } from '@datadog/browser-rum-slim';

/**
 * See:
 * https://docs.datadoghq.com/real_user_monitoring/browser/monitoring_page_performance/#add-your-own-performance-timing
 *
 * Adds a custom timing to indicate that the significant content for a route
 * has been rendered, which differs from default metrics like LCP and "page
 * load" because it is necessarily unique to each route and must be defined
 * based on our determination of when the route is usable.
 */
export function trackRouteContentRendered(time?: number) {
  datadogRum.addTiming('route_content_rendered', time);
}
