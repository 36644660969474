import { getDogfoodClient } from '@gonfalon/dogfood-client';

import { RUMCustomErrorContext } from './types';

export function trackDogfoodError({ isCrash, severity }: RUMCustomErrorContext = {}) {
  getDogfoodClient().track('Gonfalon frontend errors');

  if (isCrash) {
    getDogfoodClient().track('Gonfalon frontend crashes');
  }

  if (severity) {
    getDogfoodClient().track(`Gonfalon frontend ${severity} severity errors`);
  }
}
