import { dogfoodContext as getDogfoodContext, secureModeContextHash } from '@gonfalon/constants';
import { cloneDeep } from '@gonfalon/es6-utils';

import { waitForDogfoodClient } from './waitForDogfoodClient';

type Attributes = {
  [key: string]: string | number | boolean;
};

export async function identifyWithAttributes(attributesByKind: { [contextKind: string]: Attributes }) {
  const { identify } = await waitForDogfoodClient();
  const dogfoodContext = getDogfoodContext();

  if ('kind' in dogfoodContext) {
    if (dogfoodContext.kind === 'user') {
      // context is single kinded user - also not expected to happen in practice
      const newContext = 'user' in attributesByKind ? { ...dogfoodContext, ...attributesByKind.user } : dogfoodContext;
      return identify(newContext, secureModeContextHash());
    } else if (dogfoodContext.kind === 'multi') {
      Object.keys(attributesByKind).forEach((contextKind) => {
        if (!Object.prototype.hasOwnProperty.call(dogfoodContext, contextKind)) {
          /* eslint-disable-next-line no-console */
          console.error(`Cannot add attribute(s) to missing '${contextKind}' context`);
        }
      });
      const newContext = Object.keys(attributesByKind).reduce(
        (acc, contextKind) => ({
          ...acc,
          [contextKind]: { ...acc[contextKind], ...attributesByKind[contextKind] },
        }),
        cloneDeep(dogfoodContext),
      );
      return identify(newContext, secureModeContextHash());
    }
  }

  throw new Error('Dogfood context does not match expected schema');
}
