export const environmentScoped = ['users', 'segments', 'contexts', 'live', 'metrics/analytics'];
export const projectScoped = ['features', 'flags', 'goals', 'metrics', 'metrics/analytics', 'integrations'];
export const unscoped = [
  'settings',
  'projects',
  'check-your-email',
  'verify-email',
  'approvals',
  'internal',
  'profile',
];
