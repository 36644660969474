import { datadogRum } from '@datadog/browser-rum-slim';
import type { JSONObject } from '@gonfalon/types';

export function sendMeasureToDatadog(measure: PerformanceEntry, context?: JSONObject) {
  datadogRum.addAction(measure.name, {
    custom: {
      measures: {
        [measure.name]: context ? { ...context, duration: measure.duration } : measure.duration,
      },
    },
  });
}

export function stopTimer(measureName: string, context?: JSONObject) {
  // If we never started the timer, we should not stop it. Otherwise,
  // the browser will through an error when trying to measure the duration.
  if (performance.getEntriesByName(`${measureName}:start`).length === 0) {
    return;
  }

  performance.mark(`${measureName}:end`);

  performance.measure(measureName, `${measureName}:start`, `${measureName}:end`);
  performance.getEntriesByName(measureName, 'measure').forEach((entry) => sendMeasureToDatadog(entry, context));

  performance.clearMarks(`${measureName}:start`);
  performance.clearMarks(`${measureName}:end`);
  performance.clearMeasures(measureName);
}
