// We're directly importing this module to reduce the bundle size of the app entry point
// eslint-disable-next-line import/no-internal-modules, import/no-restricted-paths
import { projectScoped, unscoped } from '@gonfalon/router/src/scopes';

type ProjectAndEnvironment =
  | {
      project: null;
      environment: null;
    }
  | {
      project: string;
      environment: null;
    }
  | {
      project: string;
      environment: string;
    };

export function getProjectAndEnvironmentFromUrl(url: URL): ProjectAndEnvironment {
  const { pathname, searchParams } = url;
  const pathSegments = pathname.replace(/^\//, '').split('/');

  // Require at least 2 path segments: "/projects/:projectKey/" from the V2 URL
  // scheme is the shortest path we can extract a value from
  if (pathSegments.length < 2) {
    return {
      project: null,
      environment: null,
    } as const;
  }

  const [first, second] = pathSegments;

  // E.g. "/projects/:projKey/flags" or "/projects/:projKey/flags?env=:envKey" or "/projects/:projKey/flags?env=:envKey&selected-env=:envKey"
  if (first === 'projects') {
    return {
      project: second,
      // prefer `selected-env` if available (new shell), then `env` (old shell), and fall back to null
      environment: searchParams.get('selected-env') || searchParams.get('env') || null,
    } as const;
  }

  // E.g. "/settings/*""
  if (unscoped.includes(first)) {
    return {
      project: null,
      environment: null,
    } as const;
  }

  // E.g. "/:projKey/metrics"
  if (projectScoped.includes(second)) {
    return {
      project: first,
      environment: null,
    } as const;
  }

  // E.g. "/:projKey/:envKey/features"
  return {
    project: first,
    environment: second,
  } as const;
}
